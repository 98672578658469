html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

.rounded-img {
  border-radius: 10px 0 0 10px;
}

.variant-img-rounded {
  border-radius: 100px;
  padding: 4px !important;
}

::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
