html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

.rounded-img {
  border-radius: 10px 0 0 10px;
}

.variant-img-rounded {
  border-radius: 100px;
  padding: 4px !important;
}

::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

/* cyrillic-ext */
@font-face {
  font-family: '__Montserrat_070524';
  font-style: normal;
  font-weight: 100 900;
  font-display: optional;
  src: url(/_next/static/media/3f69592b2fe603c7.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Montserrat_070524';
  font-style: normal;
  font-weight: 100 900;
  font-display: optional;
  src: url(/_next/static/media/6325a8417175c41d.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Montserrat_070524';
  font-style: normal;
  font-weight: 100 900;
  font-display: optional;
  src: url(/_next/static/media/021bc4481ed92ece.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Montserrat_070524';
  font-style: normal;
  font-weight: 100 900;
  font-display: optional;
  src: url(/_next/static/media/99b7f73d5af7c3e2.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Montserrat_070524';
  font-style: normal;
  font-weight: 100 900;
  font-display: optional;
  src: url(/_next/static/media/4f05ba3a6752a328.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Montserrat_Fallback_070524';src: local("Arial");ascent-override: 84.97%;descent-override: 22.03%;line-gap-override: 0.00%;size-adjust: 113.92%
}.__className_070524 {font-family: '__Montserrat_070524', '__Montserrat_Fallback_070524';font-style: normal
}

.swiper {
  width: 100%;
  height: 80%;
}

.swiper-slide {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.swiper-button-next,
.swiper-button-prev {
  border-radius: 50%;
  width: 40px !important;
  height: 40px !important;
  margin: 8px;
  background-color: rgba(0, 0, 0, 0.3);
}

.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 16px !important;
  color: #eee;
  font-weight: bold;
}

.swiper-pagination-bullet {
  background-color: #5c5e62 !important;
}

.thumbSwiper {
  width: 100%;
  height: 20%;
}

.thumbSwiper .swiper-slide {
  border-radius: 5px;
  width: 20%;
  height: 100%;
  opacity: 0.4;
}

.thumbSwiper .swiper-slide-thumb-active {
  opacity: 1;
}

.thumbSwiper img {
  display: block;
  height: 100%;
  width: 100%;
  object-fit: contain;
}

/* cyrillic-ext */
@font-face {
  font-family: '__Montserrat_070524';
  font-style: normal;
  font-weight: 100 900;
  font-display: optional;
  src: url(/_next/static/media/3f69592b2fe603c7.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Montserrat_070524';
  font-style: normal;
  font-weight: 100 900;
  font-display: optional;
  src: url(/_next/static/media/6325a8417175c41d.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Montserrat_070524';
  font-style: normal;
  font-weight: 100 900;
  font-display: optional;
  src: url(/_next/static/media/021bc4481ed92ece.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Montserrat_070524';
  font-style: normal;
  font-weight: 100 900;
  font-display: optional;
  src: url(/_next/static/media/99b7f73d5af7c3e2.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Montserrat_070524';
  font-style: normal;
  font-weight: 100 900;
  font-display: optional;
  src: url(/_next/static/media/4f05ba3a6752a328.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Montserrat_Fallback_070524';src: local("Arial");ascent-override: 84.97%;descent-override: 22.03%;line-gap-override: 0.00%;size-adjust: 113.92%
}.__className_070524 {font-family: '__Montserrat_070524', '__Montserrat_Fallback_070524';font-style: normal
}

