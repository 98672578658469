.swiper {
  width: 100%;
  height: 80%;
}

.swiper-slide {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.swiper-button-next,
.swiper-button-prev {
  border-radius: 50%;
  width: 40px !important;
  height: 40px !important;
  margin: 8px;
  background-color: rgba(0, 0, 0, 0.3);
}

.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 16px !important;
  color: #eee;
  font-weight: bold;
}

.swiper-pagination-bullet {
  background-color: #5c5e62 !important;
}

.thumbSwiper {
  width: 100%;
  height: 20%;
}

.thumbSwiper .swiper-slide {
  border-radius: 5px;
  width: 20%;
  height: 100%;
  opacity: 0.4;
}

.thumbSwiper .swiper-slide-thumb-active {
  opacity: 1;
}

.thumbSwiper img {
  display: block;
  height: 100%;
  width: 100%;
  object-fit: contain;
}
